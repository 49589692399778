/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
// $fa-font-path : "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/socicon/css/socicon.css";
@import "../node_modules/metrojs/release/MetroJs.Full/MetroJs.css";
@import "../node_modules/dragula/dist/dragula.min.css";
// @import "../node_modules/pace/themes/pace-theme-flash.css";
@import "../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";
@import "../node_modules/dropzone/dist/min/dropzone.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.min.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/bootstrap-override";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

html,
body {
  height: 100%;
  scrollbar-color: #f44336 #bebecb;
  scrollbar-width: thin;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #d1d1d1 !important;
  padding: 0px !important;
  background: url(./assets/image/bg_show.jpg) no-repeat top center fixed;
  background-size: cover;
  height: auto !important;
  overflow-y: scroll !important;
}


.iti {

  position: relative !important;
  display: block;


  border-radius: 5px !important;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

// .iti .form-control {
//   border: none;
// }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: greenyellow;
}

// .casealink{
//   color: #333 !important;
// }

.casealink:hover {
  color: #ec2e2b !important;
}


.designation_slide {
  padding: 0 4px;
  width: fit-content;
  border-radius: 14px;
  background-color: #fff;
}

.width8 {
  width: 8%;
}

.width12 {
  width: 12%;
}

.width16 {
  width: 16%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width33 {
  width: 33%;
}

.width35 {
  width: 35%;
}

.width40 {
  width: 40%;
}

.width45 {
  width: 45%;
}

.width50 {
  width: 50%;
}

.content .form-controls.width50 {
  width: 50% !important;
}

.width55 {
  width: 55%;
}

.width60 {
  width: 60%;
}

.width65 {
  width: 65%;
}

.width70 {
  width: 70%;
}

.width100 {
  width: 100%;
}

.summary_page {
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  .tab_option {
    ul {
      padding-left: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      box-shadow: 2px 3px 8px #ddd;
      border-radius: 2px;
      overflow: hidden;

      li {
        list-style: none;
        text-align: center;
        width: 100%;
        padding: 10px;

        &.active {
          background: #ff312d;
          color: #fff;
        }

        &:hover {
          background: #ff312d;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .tab_content {
    border-radius: 10px;
    margin-bottom: 25px;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left_menu_text {
        width: 50%;

        .candidate_de {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;

          .number {
            width: 30%;
            font-size: 55px;
            font-weight: 600;

            span {
              font-size: 80px;
              font-weight: 600;
            }
          }

          .details {
            width: 70%;
            line-height: 24px;

            p {
              margin-bottom: 0;
              font-size: 15px;
            }

            .welcometext {
              margin-bottom: 12px;
              font-size: 19px;
              color: #ff312d;
            }

            .candidate_name {
              color: #0000ff;
              font-size: 22px;
              margin-bottom: 8px;
            }

            span {
              color: gray;
              font-size: 17px;
            }
          }
        }
      }

      .right_content_section {
        width: 45%;

        .userfull_button {
          padding: 10px 20px;
          display: flex;
          justify-content: space-around;
          align-items: stretch;

          .help {
            width: 35%;
            display: flex;
            border: 2px solid #ff312d;
            justify-self: center;
            align-items: center;
            text-align: center;
            border-radius: 10px;
            box-shadow: 2px 3px 8px #ddd;
            overflow: hidden;
            color: #ff312d;
            cursor: pointer;
            margin: 10px;

            &:hover {
              background: #ff312d;
              color: #fff;
            }

            .box {
              width: 100%;
              padding: 13px;

              i {
                font-size: 42px;
              }

              p {
                margin-bottom: 0;
                font-size: 16px;
                padding-top: 15px;
              }
            }
          }

          .guide {
            width: 35%;
            display: flex;
            justify-self: center;
            align-items: center;
            text-align: center;
            border: 2px solid #ff312d;
            border-radius: 10px;
            box-shadow: 2px 3px 8px #ddd;
            overflow: hidden;
            color: #ff312d;
            cursor: pointer;
            margin: 10px;

            &:hover {
              background: #ff312d;
              color: #fff;
            }

            .box {
              width: 100%;
              padding: 13px;

              i {
                font-size: 40px;
              }

              p {
                margin-bottom: 0;
                font-size: 17px;
                padding-top: 15px;
              }
            }
          }
        }
      }
    }

    .video_section {
      display: flex;
      justify-content: space-between;
      margin-top: 34px;
      align-items: center;

      .left_menu_text {
        width: 50%;

        .video {
          overflow: hidden;
          border-radius: 10px;
        }
      }

      .right_content_section {
        width: 46%;

        .teamcon_details {
          padding-left: 30px;

          h4 {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: 600;
          }

          p {
            margin-bottom: 5px;
            font-size: 16px;
          }
        }

        .userfull_button {
          padding: 10px 20px;
          display: flex;
          justify-content: space-around;
          align-items: stretch;

          .help {
            width: 35%;
            display: flex;
            justify-self: center;
            align-items: center;
            text-align: center;
            border-radius: 10px;
            box-shadow: 2px 3px 8px #ddd;
            overflow: hidden;
            color: #fff;
            cursor: pointer;
            background: #333;
            margin: 10px;

            &:hover {
              background: #ff312d;
              color: #fff;
            }

            .box {
              width: 100%;
              padding: 25px 10px;

              i {
                font-size: 30px;
              }

              p {
                margin-bottom: 0;
                font-size: 16px;
                padding-top: 10px;
              }
            }
          }

          .guide {
            width: 35%;
            display: flex;
            justify-self: center;
            align-items: center;
            text-align: center;
            border-radius: 10px;
            box-shadow: 2px 3px 8px #ddd;
            overflow: hidden;
            color: #fff;
            cursor: pointer;
            background: #333;
            margin: 10px;

            &:hover {
              background: #ff312d;
              color: #fff;
            }

            .box {
              width: 100%;
              padding: 25px 10px;

              i {
                font-size: 30px;
              }

              p {
                margin-bottom: 0;
                font-size: 16px;
                padding-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.candidate_details_show {
  display: flex;
  justify-content: space-between;

  @media (max-width:767px) {
    flex-direction: column;
  }

  .left_menu_text {
    width: 20%;
    // height: calc(100vh - 100px);
    background: #fff;
    overflow: scroll;
    box-shadow: 2px 5px 8px #d2c9c9;
    overflow: auto;

    @media (max-width:767px) {
      width: 100%;
      padding: 20px 20px;
      position: fixed;
      top: 65px;
      left: -100%;
      transition: 0.5s all;
      z-index: 9999;
      height: calc(100vh - 46px);

      &.isMenuOpen {
        left: 0;
        transition: 0.5s all;
      }
    }

    .candidate_de {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px;

      .number {
        width: 30%;
        font-size: 24px;
        font-weight: 600;

        span {
          font-size: 40px;
          font-weight: 600;
        }
      }

      .details {
        width: 100%;

        p {
          margin-bottom: 0;
          font-size: 13px;
        }

        .candidate_name {
          color: blue;
        }

        span {
          color: gray;
          font-size: 13px;
        }
      }
    }

    .tab_option {
      >ul {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >li {
          list-style: none;
          padding: 6px 20px;
          width: 100%;
          text-align: center;
          font-weight: 600;
          cursor: pointer;

          &.active {
            border-bottom: 2px solid red;
          }
        }
      }

      .tab_content {
        position: relative;

        .tab_text {
          padding-left: 0;

          li {
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;

            .textdetails {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .alss {
                text-align: left;

                span {
                  font-size: 13px;

                  &.Continue {
                    color: blue;
                  }
                }

              }

              i {
                padding-right: 15px;
              }

              .task_text {
                margin-bottom: 0;
              }

              span {
                color: gray;
              }
            }

            &.active {
              background: #f2f2f2;
              cursor: pointer;
            }

            &:hover {
              background: #f2f2f2;
              cursor: pointer;
            }
          }
        }

        &.left_sidecosnsi {
          height: calc(100vh - 286px);
          overflow: auto;
        }
      }
    }
  }

  .right_content_section {
    width: 80%;
    background: #f2f2f2;
    padding: 20px 20px;
    height: calc(100vh - 100px);
    overflow: auto;

    .heading {
      font-size: 25px;
      margin-bottom: 5px;
    }

    .subheading {
      font-size: 12px;
      color: gray;
    }

    .form_group {
      display: flex;
      margin-top: 25px;

      .form-group {
        width: 100%;
        margin: 5px;

        @media(max-width:767px) {
          margin: 0px;
        }

        .text-muted {
          color: #989898 !important;
        }

        label {
          font-size: 14px;

          .validate {
            color: red;
          }
        }
      }

      .switch.switch-medium {
        height: 23px;
        border-radius: 3px;
        margin-top: 9px;
      }

      .switch {
        label {
          margin-bottom: 1px;
        }

        small {
          border-radius: 0;
          box-shadow: none;
          display: none;
        }

        .switch-pane {
          flex-direction: row;

          span {
            font-size: 14px;
          }

          .switch-label-checked {
            display: block;
            padding-right: 10px;
            padding-left: 10px;
          }

          .switch-label-unchecked {
            display: block;
            padding-right: 10px;
            padding-left: 10px;
          }
        }

        &.checked {
          background: transparent;
          border-radius: 3px;
        }
      }

    }
  }
}

.switch {
  &.switch-medium {
    .switch-pane {
      .switch-label-checked {
        background: transparent;
        color: #333;
      }

      .switch-label-unchecked {
        background: #f03241;
        color: #fff;
      }
    }
  }

  &.switch-medium {
    border: 1px solid #f03241;
    overflow: hidden;

    &.checked {
      border: 1px solid #64bd63;

      .switch-pane {
        .switch-label-checked {
          background: #64bd63;
          color: #fff;
        }

        .switch-label-unchecked {
          background: transparent;
          color: #333;
        }
      }
    }

  }
}

.question_check {
  .switch {
    &.switch-medium {
      .switch-pane {
        .switch-label-checked {
          background: transparent;
          color: #333;
        }

        .switch-label-unchecked {
          background: #4CAF50;
          color: #fff;
        }
      }
    }

    &.switch-medium {
      border: 1px solid #4CAF50;
      overflow: hidden;

      &.checked {
        border: 1px solid #F44336;

        .switch-pane {
          .switch-label-checked {
            background: #F44336;
            color: #fff;
          }

          .switch-label-unchecked {
            background: transparent;
            color: #333;
          }
        }
      }

    }
  }
}

.form-group label {
  margin-bottom: 1px;
  color: #7c7a7a;
  font-size: 13px;

  @media(max-width:767px) {
    color: #000;
  }
}



.group_box {
  background: #fff;
  padding: 20px 15px;
  margin-bottom: 60px;



  .flexalign {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 5px;

    .headerse {
      margin-bottom: 1px;

      .heading {
        margin-bottom: 0px;
      }
    }
  }

  .border_box {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    overflow: hidden;
  }

  .delete_header {
    background: #ddd;
    padding: 7px 10px;
    margin-bottom: 13px;
    background-color: #d1d1d1;
  }

  .Sendmail {
    border: 1px solid #ddd;
    padding: 0 20px;
  }

  .sub_group {
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;


    .form-group {
      margin-bottom: 0;

      @media(max-width:767px) {
        width: 100%;

        .btn-outline-secondary {
          margin-bottom: 5px;
        }
      }
    }

    .form-text {
      color: #8f8888;
      font-size: 9px;
      margin-top: 0;
    }

    &.noteinfomation {
      background: #dbebfa;
      border: 1px solid #dbebfa;

      ul {
        margin-bottom: 0;
        padding-left: 20px;

        li {
          line-height: 25px;
          color: #000;
          list-style: disclosure-closed;
          margin-bottom: 10px;
        }
      }
    }

    &.noteinfomation {
      ol {
        margin-top: 8px;
        margin-bottom: 0;
        padding-left: 20px;

        li {
          line-height: 25px;
          color: #000;
          list-style: disclosure-closed;
          // list-style: radial-gradient;
          margin-left: 20px;
        }
      }
    }
  }

  .form_group {
    justify-content: space-between;

    // align-items: center;
    .form-group {
      width: auto;
      margin-bottom: 5px;

      .form_group {
        margin-top: 5px;
      }
    }

    .next {
      margin: 10px;
    }
  }

  .btn {
    @media (max-width:767px) {
      width: 100%;
    }
  }

  .delete_header {
    @media (max-width:767px) {
      flex-direction: row !important;
    }
  }

  .delete_header .btn {
    @media (max-width:767px) {
      width: auto;
    }
  }

  .wizard_details {
    @media (max-width:767px) {
      width: 100%;
    }
  }
}

.align-center {
  align-items: center;
}

@media (max-width:767px) {
  .summary_page {
    width: 100%;

    .tab_content {
      .content {
        flex-direction: column;

        .left_menu_text {
          width: 100%;

          .candidate_de {
            flex-direction: column;

            .number {
              width: 100%;
              margin-bottom: 20px;
            }
          }
        }

        .right_content_section {
          width: 100%;

          .userfull_button {
            flex-direction: column;

            .help {
              width: 100%;
              margin: 10px 0;
            }

            .guide {
              width: 100%;
              margin: 10px 0;
            }
          }
        }
      }

      .video_section {
        display: block;

        .right_content_section {
          width: 100%;

          .teamcon_details {
            padding-left: 0;
          }

          .action_btn {
            .userfull_button {
              flex-direction: column;

              .help {
                width: 100%;
                margin: 10px 0;
              }

              .guide {
                width: 100%;
                margin: 10px 0;
              }
            }
          }
        }
      }
    }

    .tab_content_new {
      .tasklist {
        flex-direction: column;

        .task {
          flex-direction: column;

          .task_box {
            width: 100%;
            margin: 10px 0;
          }
        }
      }
    }
  }

  .noteinfomation li {
    list-style: disclosure-closed !important;
    margin-bottom: 10px;
  }


  .candidate_details_show {
    .right_content_section {
      .form_group {
        flex-direction: column;
      }

      .group_box {
        .flexalign {
          flex-direction: column;

          .headerse {
            margin-bottom: 5px;

            .heading {
              font-size: 22px;
            }
          }


        }

        .sub_group {
          flex-direction: column;

          @media(max-width:767px) {
            padding: 10px;
          }
        }
      }
    }
  }

  .width20,
  .width30,
  .width40 {
    width: 100% !important;
  }
}

.acc_header {
  .searchbox {
    position: relative;

    i {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 14px;
    }
  }
}

.mat-slide-toggle-bar {
  background-color: rgb(255 0 0 / 50%);
}

.listing_area {
  &.requestpage {
    .tableconetent {
      .hrow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;

        &.actionbtngrup {
          justify-content: flex-start;
          display: flex;

          .btn {
            width: 100px;
            border-radius: 5px;
            font-size: 14px;
          }
        }

        b {
          color: red;
          // font-size: 20px;
          margin-left: 5px;
        }

        svg {
          &.copy {
            width: 20px;
            margin-left: 8px;
            height: 18px;
          }

          &.check {
            width: 18px;
            margin-left: 8px;
            height: 18px;
          }
        }
      }

    }
  }
}

.tableconetent {
  .hrow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;

    @media (max-width:767px) {
      margin-bottom: 10px;
    }

    &.nowhitespace {
      white-space: pre-wrap;
    }

    &.actionbtngrup {
      justify-content: flex-start;
      display: flex;

      .btn {
        width: 100px;
        border-radius: 5px;
        font-size: 14px;
      }
    }

    b {
      color: red;
      // font-size: 20px;
      margin-right: 5px;
    }

    svg {
      &.copy {
        width: 20px;
        margin-left: 8px;
        height: 18px;
      }

      &.check {
        width: 18px;
        margin-left: 8px;
        fill: #4caf50;
        height: 18px;
      }
    }

    .mobileShow {
      display: none;

      @media (max-width:767px) {
        display: block;
        color: #000;
        font-weight: 600;
      }

    }

    &.action {
      @media (max-width:767px) {

        i {
          font-size: 24px;
        }
      }
    }

    .datecolor {
      i {
        padding: 5px 8px;
        color: #898c8e;
        font-size: 19px;
        cursor: pointer;

        &.darkblue {
          color: #23397a;
        }

        &.darkblack {
          color: #4c4c4c;
        }

        &.darkorange {
          color: #ed6630;
        }

        &.darkgreen {
          color: #009688;
        }
      }
    }
  }

  &.greenbordercolor {
    svg {
      &.copy {
        width: 20px;
        margin-left: 8px;
        height: 18px;
        fill: #fff;
      }

      &.check {
        width: 18px;
        margin-left: 8px;
        fill: #fff;
        height: 18px;
      }
    }
  }
}

.cibade {
  padding: 5px;
  display: inline-block;
  background: #424242;
  margin: 2px;
  border-radius: 5px;
  color: #fff !important;
  font-size: 12px;
}

.request_page {
  .filter_option {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
  }

  .listing_area {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 60px;

    .tableconetent {
      box-shadow: none !important;

    }

    .tableheader {
      border-radius: 5px;

      &.mobilehide {
        @media (max-width:767px) {
          display: none;
        }

      }

    }
  }
}

.autoheight {
  height: auto !important;
}

.circleicons {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ef313d;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 3px #ddd;
  position: fixed;
  bottom: 11%;
  z-index: 99999;
  right: 34px;
  color: #fff;
  cursor: pointer;
  display: none;

  @media (max-width:767px) {
    display: flex;
  }
}


.panel_box {
  display: flex;

  .boxes {
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: inherit !important;
    background-clip: border-box;
    border-radius: 4px;
    box-shadow: 0 0px 3px 0 #a9a9a9;
    position: relative;
    margin: 15px 5px;
    width: 100%;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 10px 30px #bbbbe3;

    &.bluebg {
      background: #4f46e5;
    }

    &.yellowbg {
      background: #ff9800;
    }

    &.lightbluebg {
      background: #0ea5e9;
    }

    &.pinkbg {
      background: #f000b9;
    }

    &.greenbg {
      background: #10b981;
    }

    &.orangebg {
      background: #ff5724;
    }

    &.redbg {
      background: #f43f5e;
    }

    .text {
      font-size: 16px;
      margin-bottom: 0;
      color: #fff;
    }

    .count {
      font-size: 30px;
      font-weight: 600;
      display: block;
      color: #fff;
      display: flex;
      align-items: center;

      &.red {
        color: #fff;
      }

      &.greenc {
        color: #fff;
      }

      &.bluec {
        color: #fff;
      }

      &.grayc {
        color: #fff;
      }
    }
  }

  &.multiple {
    .boxes {
      padding: 0px;

      .text {
        font-size: 17px;
        background: #344154;
        padding: 5px 10px;
        color: #fff;
        margin-bottom: 0;
      }

      .count {
        font-size: 20px;
        margin-top: 5px;
        color: #344154;
      }

      .show-total {
        display: flex;
        justify-content: space-between;
        padding: 15px;

        .show {
          label {
            margin-bottom: 0;
            color: #6e6c6c;
          }
        }
      }
    }
  }
}

.chart {
  display: flex;
  align-items: stretch;

  az-chat {
    width: 100%;
    margin: 15px;
    height: 480px;
    margin-bottom: 0;
  }

  az-referencepolarchart {
    width: 100%;
    margin: 15px;
    margin-bottom: 0;
    height: 480px;
  }
}

.group_box_panel {
  display: flex;

  .user_group {
    width: 100%;
    margin: 15px;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 25%);

    .header {
      background: #344154;
      padding: 5px 15px;

      h4 {
        color: #fff;
      }
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border-bottom: 1px solid #ddd;
        font-size: 16px;
        align-items: center;

        span {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: red;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 12px;

          &.red {
            background: red;
          }

          &.greenc {
            background: #008000;
          }

          &.bluec {
            background: #0000ff;
          }

          &.grayc {
            background: #495057;
          }

          &.darkgrayc {
            background: rgb(109, 108, 108);
          }

          &.blackc {
            background: #333;
          }

          &.yellowc {
            background: rgb(252, 195, 9);
          }

          &.whitec {
            background: #fff;
            color: #333 !important;
          }
        }

        &:hover {
          background: #cfcfcf;
        }

        &:last-child {
          background: #585858;

          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.border-box-group {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;

  .header {
    padding: 10px 15px;

    h4 {
      font-size: 18px;
    }
  }

  .candidate_details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .details {
      margin-top: 15px;

      span {
        font-size: 14px;
        color: #a4a3a3;
        line-height: 1.42;
        padding-bottom: 4px;

      }

      p {
        color: #525252;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .progresssection {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;
        }

        i {
          color: #0089c6;
          font-size: 11px;
          margin-right: 10px;
        }
      }
    }
  }

  .content-box {
    padding: 10px 15px;
    border-top: 1px solid #ddd;

    .candidate_details {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;

      .details {
        margin-top: 15px;

        span {
          font-size: 14px;
          color: #a4a3a3;
          line-height: 1.42;
          padding-bottom: 4px;

        }

        p {
          color: #525252;
          font-size: 14px;
          margin-bottom: 5px;

          &.fileicon {
            a {
              position: relative;

              i {
                &.fa-check {
                  font-size: 11px;
                  width: 20px;
                  height: 20px;
                  background: green;
                  border-radius: 50%;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                }

                &.fa-close {
                  font-size: 11px;
                  width: 20px;
                  height: 20px;
                  background: rgb(218, 31, 47);
                  border-radius: 50%;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                }
              }
            }

          }
        }

        .progresssection {
          display: flex;
          align-items: center;

          p {
            margin-bottom: 0;
          }

          i {
            color: #0089c6;
            font-size: 11px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .group_box {
    padding-top: 0;
  }
}

.group_box_tab {
  margin: 0 15px 15px;
}

.overlap_rig {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
  top: -100px;
  bottom: 0px;
  z-index: 10;
  position: absolute;
  left: -100%;
  right: 0%;
  transition: .5s all;

  &.rig_show {
    left: 0%;
    transition: .5s all;
  }


}

.right_slide_section {
  position: fixed;
  background: rgb(0 0 0 / 36%);
  right: -100%;
  top: 0;
  z-index: 99;
  box-shadow: 2px 5px 8px #ddd;
  height: 100vh;
  width: 60%;
  transition: .5s all;

  .right_slide {
    background: #fff;
    width: 100%;
    right: 0;
    position: absolute;
    top: 0;

    .closeicon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: #333;
      position: absolute;
      top: 1px;
      left: -20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 22px;
      }
    }
  }

  &.rig_show {
    right: 0%;
    transition: .5s all;
  }

  .overscroll {
    height: 100vh;
    overflow: auto;
  }

  .referr_tab_group {

    .candidate_details {
      .details {
        padding: 0px 20px;
      }
    }
  }
}

.referr_tab_group {
  display: flex;
  align-items: center;

  .userpic {}

  .user_details_group {
    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .candidate_details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .details {
        margin-top: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-size: 14px;
          color: #a4a3a3;
          line-height: 1.42;
          padding-bottom: 4px;

        }

        p {
          color: #525252;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .progresssection {
          display: flex;
          align-items: center;

          p {
            margin-bottom: 0;
          }

          i {
            color: #0089c6;
            font-size: 11px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.dfdcfds {
  flex-wrap: wrap;
}

.flexwrap {
  flex-wrap: wrap;

  .details {
    margin-bottom: 25px;
  }
}

.flexlist {
  p {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px;
    font-weight: 600;
    background: #f3f1f1;
  }

}

.headerfefsf {
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-bottom: none;
  background: #ddd;
}

.borderde {
  border-radius: 0px 0px 5px 5px !important;

  .progresssection {
    color: #2196f3;
    margin-right: 5px;
  }

  .text {
    color: #333 !important;
    margin-left: 5px;
  }
}

.borderdess {
  border-radius: 5px 5px 0px 0px;
}

.verification {
  .verify-method {
    display: flex;
    justify-content: flex-start;

    .button {
      margin-right: 25px;
    }
  }
}

.acc_content {
  .border-box-group {
    .candidate_details {
      padding: 10px 15px;
      justify-content: space-between;
    }
  }
}

.noboardershow {
  border: none !important;
}

.noflex {
  display: block !important;
}

.sub_group {
  .content-box {
    border: 1px solid #ddd;
    padding: 0px 10px;
    border-radius: 5px;
    margin-bottom: 5px;

    .candidate_details {
      display: flex;
      flex-wrap: wrap;

      .details {
        margin-top: 15px;

        span {
          font-size: 14px;
          color: #a4a3a3;
          line-height: 1.42;
          padding-bottom: 4px;

        }

        p {
          color: #525252;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .progresssection {
          display: flex;
          align-items: center;

          p {
            margin-bottom: 0;
          }

          i {
            color: #0089c6;
            font-size: 11px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.remove-padding {
  padding: 0px !important;
}

.form-control .remove-padding {
  border: none !important;
  height: 35px;
}

.searchicons {
  position: absolute;
  top: 12px;
  right: 14px;
  color: #808080;
}

.group_box .mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 5px;
  // margin-bottom: 10px;
  padding: 0px 5px 0px 10px;
}

.group_box {
  .btn-con {
    padding: 6px 25px;
    border-radius: 3px;
  }

  .list_group_box {
    h4 {
      margin-bottom: 20px;
    }

    .card_list {
      display: flex;
      flex-wrap: wrap;

      .module_list_group {
        border: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        width: 20%;
        padding: 20px;

        .module_name {
          span {
            font-size: 14px;
            color: #a4a3a3;
            line-height: 1.42;
            padding-bottom: 4px;

          }

          p {
            margin-bottom: 0;
            color: #525252;
            font-size: 16px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .parleealign {
      display: flex;
      justify-content: space-between;
    }

    .btn-danger {
      border-radius: 3px;
    }
  }
}

.candidate_page {
  .right_box {
    .box {
      .header {
        .report {
          background: #dfdddd !important;
        }
      }
    }
  }
}

.left_box {
  position: relative;
}

.progresssection {
  position: relative;

  .btn-danger {
    position: absolute;
    right: 1px;
    top: -1px;
  }

  .btndown {
    border-radius: 3px;
    font-size: 13px;
    opacity: 1;
    background: #525252;
    border-color: #525252;
    padding: 2px 10px;

    &:active {
      background: #525252 !important;
      border-color: #525252 !important;
      opacity: .8;
    }

    &:focus {
      background: #525252;
      border-color: #525252;
      opacity: .8;
    }

    &:visited {
      background: #525252;
      border-color: #525252;
      opacity: .8;
    }

    &:hover {
      background: #525252;
      border-color: #525252;
      opacity: .8;
    }
  }
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  text-align: center;
  margin: 0 auto;

  .btn-danger {
    padding: 15px 0px;
    border-radius: 5px;
    margin: 10px;
    width: 100%;
    height: 90px;
    font-size: 18px;
    color: #bd2130;
    background: transparent;

    span {
      margin-right: 10px;
    }

    &.active {
      svg {
        fill: #fff;
      }
    }

    svg {
      height: 40px;
      fill: #bd2130;
      margin-right: 10px;
      margin: 0px 10px;
    }

    &:hover {
      background: #bd2130;
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  }
}

.tabgroup-group {
  .right_content_section {
    height: auto;
    width: 100%;
  }
}

// .mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
//   color:#333; 
// }
.noteflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finish_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #dbebfa;
  border: 1px solid #dbebfa;
  border-radius: 5px;
  padding: 10px 15px;
  overflow: hidden;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
    font-size: 17px;
  }
}

.flexalign {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.sub_group {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  margin-bottom: 10px;
}

.width25share {
  width: 35% !important;
  text-transform: capitalize;
}

// .selfie-upload .jw-modal {
//   max-width: 35%;
// }
.selfie-upload .webcam-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.gropingknmk {

  .card.card-info {
    box-shadow: none;
    background: transparent;

    .card-header {
      background: transparent;
      text-align: left;
      border-bottom: 1px solid #dfdfdf;
      color: #333;
    }
  }

}

/* The container */
.module_check {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 19px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.module_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 0%;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.module_check:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.module_check input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.module_check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.module_check .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.grouping_module {
  padding: 0px 10px;

  .checkmark {
    border-radius: 3px;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;

    &.module_list_group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;

      li {
        list-style: none;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin: 5px;
        text-align: left;
        padding: 3px 10px;
        width: 24%;
        box-shadow: 0px 0px 6px #e9e3e3;

        .module_name {
          color: #727171;
          font-size: 12px;
        }

        .module_check {
          font-size: 14px;
          width: 100%;
          margin-bottom: 0;
          text-align: left;

          .checkmark {
            top: -12px;
            right: 0px;
            height: 22px;
            width: 22px;
            background-color: #cdc6c6;
            left: inherit;
          }

          input:checked~.checkmark {
            background-color: #4caf50;
          }
        }
      }
    }

    .module_check {
      font-size: 14px;
      width: 100%;
      margin-bottom: 0;
      text-align: left;

      .checkmark {
        top: -3px;
        right: -21px;
        height: 22px;
        width: 22px;
        background-color: #cdc6c6;
        left: inherit;
      }

      input:checked~.checkmark {
        background-color: #4caf50;
      }
    }

  }

}

.speratealign {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;

  .form-control {
    width: 35% !important;

  }

  .grouping_module {
    margin-left: 20px;

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        list-style: none;
      }

      .module_check {
        .checkmark {
          right: -35px;
        }
      }
    }
  }
}

.wizadlineshow {
  .sub_group {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;

    &.noteinfomation {
      background: #dbebfa;
      border: 1px solid #dbebfa;

      ul {
        margin-bottom: 0;
        padding-left: 20px;

        li {
          line-height: 25px;
          color: #000;
          list-style: none;
        }
      }
    }
  }
}

.border_box_sec {
  background: transparent;
}

.upadte_module {
  border-top: 1px solid #ddd;
  margin-top: 5px;

  .btn {
    border-radius: 5px;
    margin: 10px 0px 1px;
    font-size: 14px;
  }

}

.question-div {
  ul {
    li {
      list-style: none;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0px solid #ddd;
      }
    }
  }
}

.mat-datepicker-toggle {
  color: #000;
}

.btn-primary {
  font-size: 13px;
  border-radius: 3px;
  padding: 3px 20px;
  border-radius: 3px;
}

.btn-danger {
  font-size: 13px;
  border-radius: 3px;
  padding: 3px 20px;
  border-radius: 3px;
}

.youphone_popup {
  .jw-modal {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    .jw-modal-body {
      width: 100%;
    }
  }

  .youphone {
    h2 {
      text-align: center;
      font-size: 31px;
      margin-bottom: 0;
    }

    span {
      text-align: center;
      display: block;
      margin-top: 10px;
      font-size: 17px;
    }

    .stepbysetup {
      margin-top: 25px;

      ul {
        position: relative;
        padding-left: 10px;

        li {
          display: flex;
          list-style: none;
          align-items: center;
          margin-bottom: 45px;
          position: relative;
          z-index: 1;

          .cirss {
            margin-top: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ebe7e7;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 1px 5px 8px #ddd;
            margin-right: 20px;

            i {
              font-size: 28px;
            }
          }

          p {
            margin-bottom: 0;
            font-size: 16px;
          }

        }

        &:after {
          content: "";
          width: 2px;
          height: 100%;
          border: 1px dashed #333;
          position: absolute;
          top: 0;
          left: 35px;
          z-index: 0;
        }
      }
    }

    .btndjhdjk {
      .btn {
        text-align: center;
        margin: 0 auto;
        background: #2424f1;
        padding: 10px 40px !important;
      }
    }
  }

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;

    i {
      cursor: pointer;
    }
  }
}

.NgxEditor__MenuBar {
  flex-wrap: wrap;
}

.small_popuo {
  .jw-modal {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:767px) {
      width: 100%;
    }

    .jw-modal-body {
      width: 100%;
    }
  }

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    margin-bottom: 15px;
    padding-bottom: 10px;

    i {
      cursor: pointer;
    }
  }

  .modal-footer {
    border-top: 1px solid #ddd;
    padding-top: 10px
  }
}

.overlay_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .text_content {
    width: 550px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 7px #ddd;
    border-radius: 5px;
    padding: 20px;

    @media(max-width:767px) {
      width: 80%;
    }
  }
}

.small_md_popuo {
  .jw-modal {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:767px) {
      width: 100%;
    }

    .jw-modal-body {
      width: 100%;
      overflow: auto;

      .check_verification {
        .wizadlineshow {
          .wizard_details {
            width: 100%;
          }
        }
      }
    }
  }

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    margin-bottom: 15px;
    padding-bottom: 10px;

    i {
      cursor: pointer;
    }
  }

  .modal-footer {
    border-top: 1px solid #ddd;
    padding-top: 10px
  }
}

.youphone {
  .qrcode {
    margin: 20px 0 40px;

    .qrtext {
      color: #233fdb;
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-top: 0;
      }
    }
  }

  .lineovertext {
    text-align: center;
    position: relative;
    z-index: 1;

    p {
      width: 50%;
      margin: 0 auto;
      background: #fff;
      position: relative;
      z-index: 10;

    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #ddd;
      position: absolute;
      left: 0;
      right: 0;
      top: 9px;
      z-index: 0;
    }
  }

  .showotheroption {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 10px;
      margin-bottom: 0;
      cursor: pointer;
      font-size: 14px;
      color: #233fdb;
    }
  }
}

.button-group {
  &.newbjhj {
    width: 40%;

    .btn {
      height: 60px;

      svg {
        height: 30px;
        fill: #fff;
      }

      span {
        font-size: 16px;
      }
    }

    .custom-btn {
      padding: 10px 25px;
      border: 2px solid #bd2130;
      font-family: 'Lato', sans-serif;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
    }

    .btn-15 {
      background: #bd2130;
      color: #fff;
      z-index: 1;
    }

    .btn-15:after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      z-index: -1;
      background: #e0e5ec;
      transition: all 0.3s ease;
    }

    .btn-15:hover {
      color: #bd2130;

      svg {
        fill: #bd2130;
      }
    }

    .btn-15:hover:after {
      left: 0;
      width: 100%;
    }

    .btn-15:active {
      top: 2px;
    }



  }
}

.topstripshow {
  background: #ef313f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;

  p {
    margin-bottom: 0;
    color: #fff;
    font-weight: bold;
  }

  .inputgroup {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: #fff;
      margin-bottom: 0;
      margin-right: 10px;

      &.calend_lab {
        color: #fff;
        margin-bottom: 0;
        margin-right: 10px;
        display: block;
        margin-left: 29px;
        position: relative;
        top: 10px;
        z-index: 999;
        background: #ef313f;
        width: 136px;
        padding-left: 10px;
      }
    }

    input {
      margin-right: 10px;
      border: none;
      height: 30px;
      // width: 200px;
      padding: 10px;
      border-radius: 3px;
    }
  }
}

.group_label {
  &.redbgss {

    margin-top: -15px;

    .datedrop {
      width: 250px;
    }

    .mat-date-range-input-container {
      .mat-date-range-input-start-wrapper {
        .mat-start-date {
          color: #fff;
          top: -7px;
        }

        .mat-date-range-input-mirror {
          color: #fff;
        }
      }

      .mat-date-range-input-end-wrapper {
        .mat-end-date {
          color: #fff;
        }
      }

      .mat-date-range-input-separator {
        color: #fff !important;
      }


    }

    .notranslate {
      color: #fff;
    }

    .mat-datepicker-toggle {
      .mat-focus-indicator {
        .mat-button-wrapper {
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

.listing_area {
  &.requestpage {
    .tableheader {
      margin-top: 10px !important;
    }

    .inputgroup {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      label {
        color: #333;
        margin-bottom: 0;
        margin-right: 10px;
      }

      input {
        margin-right: 10px;
        border: 1px solid #a9a9a9;
        height: 30px;
        width: 200px;
        padding: 10px;
        border-radius: 3px;

        @media(max-width:767px) {
          width: 98%;
          margin-bottom: 10px;

        }
      }

      @media(max-width:767px) {
        display: block;

      }
    }

  }

}

.request_page .filter_option .buttongroup .btn.newrequest {
  padding: 3px 19px;
  font-size: 13px;
}

.panel_box.listremain {
  float: left;
  width: 100%;
  display: block;
}

.panel_box.listremain .boxes {
  width: 22.9%;
  float: left;
}


.info-box {
  color: $default-color;

  .card-block {
    padding: 1rem;
  }

  .info-box-icon {
    display: flex;
    font-size: 50px;
    opacity: 0.9;
  }

  h2,
  h6,
  span,
  p {
    font-weight: $font-light;
    letter-spacing: 0.03em;
  }

  h6 {
    white-space: nowrap;
    font-size: 14px;
  }

  p {
    font-size: 14px;
    line-height: 1;
  }
}

.small-info-box {
  padding: 8px;
  color: $default-color;
  background-color: $info-color;

  div.media {
    align-items: center;
  }

  i {
    font-size: 28px;
  }

  h6,
  span {
    font-weight: $font-normal;
  }

  h6 {
    font-size: 14px;
  }
}

.user-info-box {
  color: #fff;

  .card-body {
    padding: 3px;

    img {
      width: 84px;
    }

    h6 {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 300;
    }

    a {
      font-size: 20px;
      color: $default-color;
    }
  }
}


.live-tile {
  margin: 0;
  width: 100%;
  color: inherit;
}

.width_icons {
  width: 40px;
  display: inline-block;
}

.request_page .listing_area .tableconetent .hrow {
  span {
    a {

      display: block;
      color: #0404cd;

      &.casealink {
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;

        &.copyicon {
          width: 125px;
        }
      }
    }
  }
}

.rightsidetab {
  display: flex;
  align-items: center;

  .btn {
    border-radius: 3px;
  }

  .uploadreport {
    background: #dfdddd;
    border-radius: 3px;
  }

  .btn-dark.reports {
    background: #333 !important;
    border-radius: 3px;
    margin-right: 10px;
  }
}

.noboarderradiusshow {
  border-radius: 0 !important;
}

.weather {
  img {
    width: auto !important;
  }

  h2,
  p {
    font-weight: 300;
    color: $default-color;
  }

  span {
    color: $default-color;
  }

  .current-temperature {
    font-size: 70px;
    color: $default-color;
    display: inline-block;
  }

  .degree {
    color: $default-color;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    font-size: 20px;
  }

  .card-body {
    padding: 0;

    canvas {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .line {
    padding: 5px 15px;
    border-bottom: 1px solid rgba($main-color, 0.2);

    &:last-child {
      border: none;
    }
  }
}

// jw-modal .jw-modal {
//   overflow: auto !important;
// }

.candetails .jw-modal {
  display: block;
  max-height: inherit !important;
  justify-content: center;
  align-items: center;

  .modal-body {
    max-height: calc(100vh - 100px);

  }
}

// jw-modal .jw-modal {
//   overflow: auto !important;
// }

#add-screening-referees {
  app-screeningaddreferees {
    .check_verification {
      .wizadlineshow {
        width: 100%;
        margin: 0 auto;

        .wizard_img_box {
          display: none;
        }

        .overviewsshow {
          box-shadow: none;
          margin-top: 5px !important;
        }
      }
    }
  }
}

.boarderfull {
  border: 1px solid #ddd;
}

@media(max-width:1200px) {
  .newrequest_cre .content_area {
    flex-direction: column;
  }

  .summary_page .tab_content .content .left_menu_text .candidate_de .number {
    width: 35%;
    font-size: 36px;
    font-weight: 600;
  }

  .summary_page .tab_content .content .left_menu_text .candidate_de .details .welcometext {
    font-size: 16px;
  }

  .summary_page .tab_content .content .left_menu_text .candidate_de .details span {
    color: gray;
    font-size: 14px;
  }

  .summary_page .tab_content .video_section .left_menu_text {
    width: auto;
  }

  .summary_page .tab_content .video_section .right_content_section {
    width: 100%;
  }
}

@media (max-width:1024px) {
  .panel_box {
    flex-wrap: wrap;
    justify-content: space-between;

    .boxes {
      margin: 5px;
      width: 32%;
    }

    &.listremain {
      display: flex;

      .boxes {
        width: 30%;
        display: flex;
      }
    }
  }

  .summary_page .tab_content .content {
    flex-wrap: wrap;
  }

  .summary_page .tab_content .content .left_menu_text {
    width: 100%;
  }

  .summary_page .tab_content .content .right_content_section {
    width: 100%;
  }

  .summary_page .tab_content .video_section .right_content_section {
    width: 100%;
  }

  .dflex {
    flex-wrap: wrap;
  }

  .summary_page .tab_content .video_section {
    flex-wrap: wrap;
  }
}

@media (max-width:980px) {
  .panel_box {
    flex-wrap: wrap;
    justify-content: space-between;

    .boxes {
      margin: 10px;
      width: 30%;
    }

    &.listremain {
      display: flex;

      .boxes {
        width: 30%;
        display: flex;
      }
    }
  }

  .summary_page .tab_content .content {
    flex-wrap: wrap;
  }

  .summary_page .tab_content .content .left_menu_text {
    width: 100%;
  }

  .summary_page .tab_content .content .right_content_section {
    width: 100%;
  }

  .summary_page .tab_content .video_section .right_content_section {
    width: 100%;
  }

  .dflex {
    flex-wrap: wrap;
  }

  .summary_page .tab_content .video_section {
    flex-wrap: wrap;
  }

}

@media (max-width:767px) {
  .button-group {
    flex-direction: column;
    width: 100%;

    .btn-danger {
      height: auto;
      display: flex;
      align-items: center;
      padding: 8px 0;
      margin: 5px;

      svg {
        height: 30px;
      }

      span {
        font-size: 17px;
      }
    }
  }

  .sub_group {
    &.tabgroup-group {
      .right_content_section {
        padding: 0px;

        .group_box {
          padding: 0;
        }
      }
    }

  }

  .wizadlineshow {
    width: 100%;
    margin-top: 0;

    .wizard_ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        &.step {
          margin: 0;
          margin-bottom: 10px;
          width: 100% !important;
        }
      }

    }

    .margin {
      margin-left: 0 !important;
    }
  }

  .finish_box {
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }

    .addbutton {
      width: 100%;
    }
  }

  .width25share {
    width: 100% !important;
  }

  .newrequest_cre .content_area {
    flex-direction: column;
  }

  .new_request .importcsv {
    flex-direction: column;
  }

  .panel_box {
    flex-direction: column;

    .boxes {
      margin: 10px 0px;
      width: 100%;
    }

    &.listremain {
      .boxes {
        width: 100%;
      }
    }
  }

  .summary_page .tab_content .content .left_menu_text .candidate_de .number {
    width: 100%;
  }

  .summary_page .tab_content .content .left_menu_text .candidate_de .details {
    width: 100%;
  }

  .summary_page .tab_content .content .left_menu_text .candidate_de {
    align-items: flex-start;
  }

  .summary_page .tab_content .video_section .right_content_section .userfull_button {
    padding: 10px 0px;
  }
}

@keyframes growProgressBar {

  0%,
  33% {
    --pgPercentage: 0;
  }

  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 2rem;
  --fg: #256bd1;
  --bg: #f5a9ad;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-right: 5px;
  background: radial-gradient(closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
}

.circletext {
  font-size: 10px;
  font-weight: 600;
}

// div[role="progressbar"]::before {
//   counter-reset: percentage var(--value);
//   content: counter(percentage) "%";
// }


div.circleswing {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: conic-gradient(#256bd1 var(--progress), #f5a9ad 0deg);
  font-size: 0;
  animation: .4s ease-out turn_in reverse;
  position: relative;
  margin-right: 5px;
}

div.circleswing::after {
  content: attr(data-progress);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 24px;
  margin: 4px;
  border-radius: 50%;
  background: white;
  font-size: 10px;
  text-align: center;
  height: 24px;
}

@keyframes turn_in {
  5% {
    background: conic-gradient(red calc(var(--progress) * .95), gray 0deg);
  }

  10% {
    background: conic-gradient(red calc(var(--progress) * .9), gray 0deg);
  }

  15% {
    background: conic-gradient(red calc(var(--progress) * .85), gray 0deg);
  }

  20% {
    background: conic-gradient(red calc(var(--progress) * .8), gray 0deg);
  }

  25% {
    background: conic-gradient(red calc(var(--progress) * .75), gray 0deg);
  }

  30% {
    background: conic-gradient(red calc(var(--progress) * .7), gray 0deg);
  }

  35% {
    background: conic-gradient(red calc(var(--progress) * .65), gray 0deg);
  }

  40% {
    background: conic-gradient(red calc(var(--progress) * .6), gray 0deg);
  }

  45% {
    background: conic-gradient(red calc(var(--progress) * .55), gray 0deg);
  }

  50% {
    background: conic-gradient(red calc(var(--progress) * .5), gray 0deg);
  }

  55% {
    background: conic-gradient(red calc(var(--progress) * .45), gray 0deg);
  }

  60% {
    background: conic-gradient(red calc(var(--progress) * .4), gray 0deg);
  }

  65% {
    background: conic-gradient(red calc(var(--progress) * .35), gray 0deg);
  }

  70% {
    background: conic-gradient(red calc(var(--progress) * 0.3), gray 0deg);
  }

  75% {
    background: conic-gradient(red calc(var(--progress) * 0.25), gray 0deg);
  }

  80% {
    background: conic-gradient(red calc(var(--progress) * .2), gray 0deg);
  }

  85% {
    background: conic-gradient(red calc(var(--progress) * .15), gray 0deg);
  }

  90% {
    background: conic-gradient(red calc(var(--progress) * .1), gray 0deg);
  }

  95% {
    background: conic-gradient(red calc(var(--progress) * .05), gray 0deg);
  }

  100% {
    background: conic-gradient(gray 0deg);
  }
}

.circleswing {
  span {
    color: #686868;

    &.circletext {
      color: #686868 !important;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      top: 0
    }
  }
}

.textwrap {
  white-space: pre-wrap !important;
}

@media (max-width:767px) {
  .tableheader.mobilehide {
    display: none;
  }
}

.otp_downlaod_section .otp_text {
  text-align: center;
}

.otp_downlaod_section .otp_text h2 {
  margin-bottom: 0px;
  line-height: 38px;
  font-size: 28px;
}

.otp_downlaod_section .otp_text {
  margin-bottom: 30px;
}

.otp_downlaod_section .otp_text span {
  font-size: 14px;
  color: #787777;
}

.otp_downlaod_section .otp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp_downlaod_section .otp .otp_box {
  width: 70px;
  height: 70px;
  font-size: 55px;
  text-align: center;

}

.otp_downlaod_section .andiosicons .iocns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp_downlaod_section .andiosicons .iocns img {
  width: 245px;
  cursor: pointer;
}

.otp_downlaod_section .andiosicons {
  margin-top: 25px;
}

.btnoptp .btn-primary {
  padding: 6px 50px;
  height: 50px;
}

.otp_downlaod_section {
  width: 45%;
  margin: 25px auto;
  background: #fff;
  padding: 35px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #cdcdcd;
}


.checkinclude {
  margin-left: 5px;
  padding-left: 0px;

  li {
    text-decoration: none;
    list-style: none;
    line-height: 25px;
    color: #525252;
  }

  i {
    color: #4fc3f7;
    font-size: 11px;
    margin-right: 10px;
  }

}

.candidate_details {
  .details {
    img {
      margin-right: 10px;
      border-radius: 10px 10px;
    }
  }
}

.box-shadow-0 {
  box-shadow: 0px 0px !important;
}


.candidate_page {
  .right_box {
    .flag_red {
      svg {
        fill: green;
        height: 23px;
      }
    }

    .flag_green {
      svg {
        fill: red;
        height: 23px;
      }
    }
  }
}

.fullreport_option {
  padding: 45px 0 10px;
  display: flex;
  justify-content: flex-end;

  .report {
    color: #686868;
    background: transparent;
    border: none;
    border-radius: 3px;

    &:hover {
      background: #ddd;
    }
  }
}

.candidate_page {
  display: flex;
  justify-content: space-between;

  .left_box {
    &.tableftbg {}

    .box {
      background: #fff;
      padding: 15px;
      box-shadow: 1px 2px 3px #ddd;
      margin-bottom: 20px;

      h4 {
        font-size: 28px;
        margin-bottom: 15px;

        .totalcount {}
      }

      h3 {
        margin-bottom: 5px;
        font-size: 18px;
        color: #525252;
      }

      &.tabnumbecountal {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        h4 {
          font-size: 140px;

          .totalcount {
            font-size: 100px;
            color: #ff312d;
          }
        }

        .textinfocomp {
          margin-bottom: 0;
          font-size: 13px;
          position: absolute;
          bottom: 0;
          padding: 12px;
          line-height: 20px;
          text-align: center;

          .countcomp {
            font-size: 16px;
            color: #f00;
            font-weight: 600;
          }
        }
      }

      .info_sec {
        p {
          margin-bottom: 5px;
          display: flex;
          align-items: center;

          i {
            margin-right: 10px;
            font-size: 17px;
            color: #525252;
            width: 18px;
          }
        }
      }

      .progresssection {
        display: flex;
        margin-top: 17px;

        .circlebox {
          box-sizing: border-box;
          margin: 0px;
          min-width: 0px;
          max-width: 100%;
          width: 18px;
          height: 18px;
          display: block;
          border: 4px solid #0089c6;
          border-radius: 50%;
          margin-right: 5px;
        }

        .text {
          color: #0089c6;
        }
      }

      hr {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 3px solid #0089c6;
      }

      .candidate_details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .details {
          span {
            font-size: 14px;
            color: rgb(164, 163, 163);
            line-height: 1.42;
            padding-bottom: 4px;
          }

          p {
            color: rgb(82, 82, 82);
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }

      .timeline_box {
        .timeline {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .groupbox {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            i {
              margin-right: 15px;

              &.fa-user {
                color: #6c6c6c;
                font-size: 11px;
              }

              &.fa-circle {
                color: #0089c6;
                font-size: 11px;
              }

              &.fa-arrow-left {
                color: #4fc3f7;
                font-size: 11px;
              }
            }
          }

          .timeline_datetext {
            p {
              margin-bottom: 0;
              color: #525252;
            }

            .date {
              color: #a4a3a3;
            }
          }
        }
      }
    }
  }

  .right_box {
    margin-left: 20px;
    margin-bottom: 40px;

    .box {
      background: #fff;
      padding: 15px;
      box-shadow: 4px 4px 4px 4px #ddd;
      margin-bottom: 20px;
      border-radius: 4px 4px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .report {
          color: #686868;
          background: transparent;
          border: none;
          border-radius: 3px;

          &:hover {
            background: #ddd;
          }

          i {
            margin-right: 10px;
          }
        }

        h3 {
          margin-bottom: 5px;
          font-size: 18px;
          color: #525252;

          &.usernamehight {
            font-size: 21px;
            color: #353fd2;
            width: 50%;
          }
        }

        .welcometext {
          color: #ff312d;
          font-size: 18px;
        }
      }

      .profileuser {
        display: flex;
        justify-content: space-between;

        .group_box {
          display: flex;
          justify-content: space-between;

          .circle_box_lg {
            margin-right: 15px;
            text-align: center;

            .circlelg {
              width: 125px;
              height: 125px;
              border-radius: 50%;
              border: 17px solid #ddd;
              display: block;
              margin-bottom: 5px;
              margin: 5px auto;
            }

            p {
              margin-bottom: 0;
              color: #7a7a7a;
              font-size: 14px;
            }
          }

          .detailsbox {
            h4 {
              font-size: 18px;
              margin-bottom: 10px;
            }

            .info_sec {
              p {
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                color: #868686;

                i {
                  margin-right: 10px;
                  font-size: 17px;
                  color: #868686;
                  width: 18px;
                }
              }
            }

          }
        }

        .progresssection {
          display: flex;
          align-items: center;

          .text {
            color: #747474;
          }

          i {
            color: #0089c6;
            font-size: 11px;
            margin-right: 10px;
          }
        }
      }
    }

    .accordion_box {
      background: #fff;
      box-shadow: 1px 2px 3px #ddd;
      margin-bottom: 20px;

      .acc_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #ddd;

        h4 {
          font-size: 18px;
        }

        i {
          font-size: 22px;
          cursor: pointer;
        }
      }

      .acc_content {
        padding: 15px;

        .candidate_details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;

          .details {
            span {
              font-size: 14px;
              color: rgb(164, 163, 163);
              line-height: 1.42;
              padding-bottom: 4px;
            }

            p {
              color: rgb(82, 82, 82);
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .rightalks {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    a {
      .box {
        width: 125px;
        height: 125px;
        box-shadow: 0px 9px 8px #c1b7b7;
        color: #fff;
        margin: 20px 20px 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 5px;
        transition: .5s all;

        &:hover {
          box-shadow: none;
          transition: .5s all;
        }

        .cealg {
          text-align: center;

          i {
            font-size: 30px;
            margin-bottom: 10px;
          }

          p {
            margin-bottom: 0;
            font-size: 14px;
          }
        }

      }

      &.taskbtn {
        .box {
          background: #ff312d;
        }

      }

      &.help {
        .box {
          background: #ff312d;
        }
      }

      &.guide {
        .box {
          background: #ff312d;
        }
      }

      &.resetbtn {
        .box {
          background: #333;
        }

      }

      &.withdraw {
        .box {
          background: #333;
        }
      }

      &.Passwordbtn {
        .box {
          background: #333;
        }
      }

      &:last-child {
        .box {
          // margin-right: 0;
        }

      }
    }

    &:first-child {
      border-right: 1px solid #dddd;
    }
  }

  .footertabde {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;

    h4 {
      font-size: 18px;
    }

    .infoemph {
      display: flex;

      p {
        margin-bottom: 0;
        padding: 5px 10px;
        font-size: 12px;

        i {
          margin-right: 2px;
          font-size: 17px;
          color: #525252;
          width: 18px;
        }

        &:first-child {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
}

.spaceadj {
  margin-top: 10px;
  margin-bottom: 0px;
}

.grayboarder {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.notmation {
  color: #f5a623;
}

.width2 {
  width: 2%;
}

.width8 {
  width: 8%;
}

.width16 {
  width: 16%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width35 {
  width: 35%;
}

.width40 {
  width: 40%;
}

.width45 {
  width: 45%;
}

.width50 {
  width: 50%;
}

.width55 {
  width: 55%;
}

.width60 {
  width: 60%;
}

.width65 {
  width: 65%;
}

.width70 {
  width: 70%;
}

.width100 {
  width: 100%;
}

.rtwshow {
  display: none;
}

.checkbox_new {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left !important;
  margin-bottom: 7px;
}

/* Hide the browser's default checkbox */
.checkbox_new input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 3px !important;
}

/* On mouse-over, add a grey background color */
.checkbox_new:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_new input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_new input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_new .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

::ng-deep {
  .candetails {
    .jw-modal {
      display: flex;

      @media (max-width: 767px) {
        display: block;
      }

      .jw-modal-body {
        align-self: center;
      }
    }
  }
}

.tasklist {
  // padding: 20px;
  // margin-bottom: 20px;
  display: flex;
  align-items: center;

  .task {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .task_box {
      background: #fff;
      box-shadow: 2px 3px 8px #ddd;
      padding: 25px 30px;
      text-align: center;
      margin: 9px;
      border-radius: 5px;
      align-self: stretch;
      width: 18.65%;
      transition: .5s all;

      .box {
        align-self: stretch;
      }

      h4 {
        font-size: 18px;
        min-height: 60px;
      }

      .icons {
        padding: 20px;

        i {
          font-size: 45px;
        }
      }

      .icons_user {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #333;
        margin: 5px auto;

        img {
          width: 100%;
        }
      }

      .bottom_details {
        .mandory {
          display: block;
          font-size: 12px;
          color: #837979;
        }

        .incomplete {
          margin-bottom: 5px;
        }

        .btn {
          padding: 6px 30px;
          border-radius: 2px;
          margin-bottom: 5px;
          background: #ff312d;
          box-shadow: 0px 4px 8px #c1b7b7;
          border-color: #ff312d;
        }
      }

      &:hover {
        background: #ff312d;
        transition: .5s all;

        h4 {
          color: #fff;
        }

        .icons {
          i {
            color: #fff;
          }
        }

        .bottom_details {
          .incomplete {
            color: #fff;
          }

          .complete {
            color: #fff;
          }

          .btn {
            color: #302f2f;
            background: #fff;
            box-shadow: 0px 4px 8px #b12222;
            border-color: #fff;
          }

          .mandory {
            color: #fff;
          }
        }

      }
    }
  }
}

.adminpanel_section {
  padding: 20px;
  padding-bottom: 0;

  .checkoption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      word-wrap: break-word;
      background-color: #fff;
      border: 1px solid transparent;
      background-clip: border-box;
      border-radius: 5px;
      background-clip: border-box;
      box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
      padding: 25px;
      width: 24%;
      margin: 10px 5px;

      .content {
        p {
          font-size: 16px;
        }

        .value {
          font-size: 28px;
          font-weight: 600;
        }
      }

      .iconsblock {
        &.blue {
          background: linear-gradient(to bottom right,
              #9e88f5 0%,
              #6259ca 100%);
          box-shadow: 0 5px 10px #c4cdeb;
        }

        &.orange {
          background-image: linear-gradient(to bottom right,
              #f1bf64 0%,
              #f71d36 100%);
          box-shadow: 0 5px 10px #efc2c9;
        }

        &.purpule {
          background: linear-gradient(to bottom right,
              #9070ff 0%,
              #ff5d9e 100%);
          box-shadow: 0 5px 10px #e4cbfb;
        }

        &.green {
          background: linear-gradient(to bottom right,
              #4be8d4 0%,
              #129bd2 100%);
          box-shadow: 0 5px 10px #99daea;
        }

        &.red {
          background: linear-gradient(to bottom right,
              #e91e63 0%,
              #f85e92 100%);
          box-shadow: 0 5px 10px #f0b0c5;
        }

        &.bluelight {
          background: linear-gradient(to bottom right,
              #ff9800 0%,
              #ffc107 100%);
          box-shadow: 0 5px 10px #e8d395;
        }

        &.black {
          background: linear-gradient(to bottom right, #333 0%, #000 100%);
          box-shadow: 0 5px 10px #ddd;
        }

        &.perate {
          background: linear-gradient(to bottom right,
              #8bc34a 0%,
              #a9e860 100%);
          box-shadow: 0 5px 10px #cdf2a1;
        }

        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        font-size: 24px;
        border-radius: 50%;

        i {
          color: #fff;
        }
      }
    }

    &.useroption {
      .option {
        &.blue {
          background: linear-gradient(to bottom right,
              #9e88f5 0%,
              #6259ca 100%);
        }

        &.orange {
          background-image: linear-gradient(to bottom right,
              #f1bf64 0%,
              #f71d36 100%);
        }

        &.purpule {
          background: linear-gradient(to bottom right,
              #9070ff 0%,
              #ff5d9e 100%);
        }

        &.green {
          background: linear-gradient(to bottom right,
              #4be8d4 0%,
              #129bd2 100%);
        }

        .content {
          p {
            color: #fff;
          }

          .value {
            color: #fff;
          }
        }

        .iconsblock {
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.fa.fa-upload {
  color: #fff;
}

.admintwo_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 50px;

  .colleft {
    background: #fff;
    width: 50%;
    margin: 5px;
    background-clip: border-box;
    box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
    border-radius: 5px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid #ddd;

      .left {
        i {
          padding-right: 5px;
        }

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .right {
        i.fa-angle-up {
          font-size: 19px;
        }

        i {
          padding: 8px;
          cursor: pointer;
        }
      }
    }

    .tablelist {
      padding: 10px;

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin-bottom: 5px;

        .firstl {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .circle {
            width: 18px;
            height: 18px;
            display: inline-block;
            border: 3px solid #4DB6AC;
          }

          h5 {
            margin-bottom: 0;
            margin-left: 5px;
            font-size: 14px;
            color: #616cf0;
            font-weight: 600;
            text-decoration: underline;
          }
        }

        p {
          margin-bottom: 0;
        }

        &:hover {
          background: #f8f8f8;
          cursor: pointer;
        }
      }
    }
  }
}

.new_request .entryform .formtextbox .form-control {
  height: 44px !important;
  border-radius: 2px !important;
}

.mat-form-field-infix {
  top: -7px;
}

.mat-select-arrow {
  position: relative;
  top: 15px;
  color: #000;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #000000;
}

@media (max-width:1024px) {
  .tasklist .task .task_box {
    width: 30.5%;
  }
}

@media (max-width:768px) {
  .tasklist .task .task_box {
    width: 45%;
  }
}


.fileicon {
  display: flex;
}

.fileicon i {
  font-size: 50px;
}

.no_bg {
  background: transparent;
  border: 1px solid #ddd;
}

.alignchs {
  align-items: center;
}

.application_block {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;

  .appli_header {
    background: #eee;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #ddd;

    .left_box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-group {
        margin-bottom: 0;

        p {
          margin-bottom: 0;
        }

      }

      .appli_index {
        color: #fff;
        font-size: 27px;
        margin-right: 15px;
        display: flex;
        background: #333;
        height: 45px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 5px;
      }
    }

    .createby {
      color: red;
      margin-bottom: 0;
    }

    .rightconsk {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        margin-left: 10px;
        font-size: 21px;
      }
    }
  }

  .application_content {
    padding: 20px;
  }
}

.border_top {
  border-top: 1px solid #ddd;
}

.checkoptions {
  display: flex;
  justify-content: flex-start;

  .fillbutton {
    box-sizing: border-box;
    margin: 0px 10px 0px 0px;
    min-width: 0px;
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    text-decoration: none;
    border-right: 0px;
    border-left: 0px;
    border-image: initial;
    border-radius: 4px;
    line-height: 1.5;
    cursor: pointer;
    padding: 3px 10px;
    height: 36px;
    background-color: white;
    color: #a4a3a3;
    font-family: SFProTextRegular;
    font-size: 14px;
    outline: none;
    border-top: none;
    box-shadow: #e5e6eb 0px 0px 0px 1px inset;
    border-bottom: none;
    display: flex;
    align-items: center;

    .bPRapZ {
      box-sizing: border-box;
      margin: 0px;
      min-width: 0px;
      height: 16px;
      width: 16px;
      flex-shrink: 0;
      border: 1px solid #ddd;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .threebox {
    width: 32%;
    margin: 0px 5px;
    border-radius: 3px !important;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.paginationbox {
  justify-content: flex-end !important;
  display: flex;

  .number {
    display: flex;

    button {
      border: none;
      border-radius: 6px;
      background: #f0f0f0;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      padding: 0 6px;
      margin: 0 3px;
      align-items: center;

      &.firstpage {
        height: 30px;
        width: 30px;

        &:disabled {
          background: transparent;
          color: #999;
          border: 1px solid #f0f0f0;
        }
      }

      &.lastpagepage {
        height: 30px;
        width: 30px;

        &:disabled {
          background: transparent;
          color: #999;
          border: 1px solid #f0f0f0;
        }
      }

      &.lastarrow {
        width: 30px;

        &:disabled {
          background: transparent;
          color: #999;
          border: 1px solid #f0f0f0;
        }
      }

      &.firstarrow {
        width: 30px;

        &:disabled {
          background: transparent;
          color: #999;
          border: 1px solid #f0f0f0;
        }
      }
    }

    .pagenumber {
      width: 30px;
      display: flex;
      justify-content: center;
      text-decoration: none !important;
      margin: 0 2px;

      &.active {
        width: 30px;
        display: flex;
        justify-content: center;
        background: #565656;
        border-radius: 6px;
        color: #fff !important;
      }

      &:hover {
        width: 30px;
        display: flex;
        justify-content: center;
        background: #565656;
        border-radius: 6px;
        color: #fff !important;
      }
    }
  }
}

.copyicon {
  width: 112px;
  display: inline-block;
}

.form-control {
  font-size: 14px;

  @media (max-width:767px) {
    min-width: 95%;
  }

}

.rowdata_align {
  display: flex;
}

.sclass {
  font-size: 10.7b px;
}

.listing_area {
  .tableheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(70, 70, 70, 0.83);
    padding: 7px;
    margin: 25px 0 10px;
    border-radius: 0px;
  }

  .tableconetent {
    display: flex;
    justify-content: space-between;
    padding: 5px 7px;
    background: #fff;
    border-radius: 0px;
    align-items: center;
    margin-bottom: 5px;
    border: 1px solid #ddd;

    .hrow1 {
      text-overflow: clip !important;
    }

    .hrow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;

      .mobileShow {
        display: none;
      }

      span {
        color: #686868;
      }
    }

    &.greenbordercolor {
      background: #b2eed6 !important;
      border: 1px solid #6edfb2 !important;
      color: #000000;

      span {
        color: #585656 !important;
      }

      .action {
        i {
          color: #767171 !important;
        }
      }
    }

    &.yellowbordercolor {
      background: #ffdead !important;
      border: 1px solid #f9b053 !important;
      color: #000;

      span {
        color: #585656 !important;
      }

      .action {
        i {
          color: #767171 !important;
        }
      }
    }

    &.redbordercolor {
      background: #f15f6a !important;
      border: 1px solid #dc143c;
      color: #fff;

      span {
        color: #302d2d !important;
      }

      .actionbtngrup {
        i {
          color: rgb(51 51 51 / 68%) !important;
        }
      }

      .progressgropepe {
        .aldihfhf {
          .text {
            color: #302d2d;
          }
        }
      }
    }

    .btn-danger {
      background: rgb(233 30 47);
      border-color: rgb(255 0 21 / 80%);
    }

    .btn-success {
      background: #37d395;
      border-color: #37d395;

      &:hover {
        background: #26ae78;
        border-color: #26ae78;
      }

      &:focus {
        background: #26ae78;
        border-color: #26ae78;
      }
    }

    // &:hover{
    //   background: #f5f5f5 !important;
    // }
  }

}

.aligngroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .align_radio {
    display: flex;
    margin-right: 5px;

    input {
      margin-right: 5px;
    }
  }
}

.jspave {
  justify-content: space-between !important;
}

.nowhitespace {
  white-space: pre-wrap !important;
}

input[type="date"] {
  background-image: url('../../screeningplatform//src/assets/image/calendar.png');
  /* Replace 'calendar-icon.png' with the actual path to your calendar icon image */
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: right 8px;
  background-position-y: center;
  background-position-x: 96%;
  min-height: 35px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.progresscolor::-moz-progress-bar {
  accent-color: green;
  width: 75px
}

.progresscolor::-webkit-progress-value {
  accent-color: green;
  width: 75px
}

.progresscolor {
  accent-color: green;
  width: 75px;
}

.progressgropepe {
  display: flex;
  align-items: center;

  .aldihfhf {
    .flejdj {
      display: flex;
      align-items: center;

      .progresscolor {
        margin-right: 8px;
      }

      .circletext {
        font-size: 13px;

        &.progress-text {
          color: #f31818;
        }

      }
    }
  }

}

.request_page {
  padding: 10px 0;

  .filter_option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchbox {
      position: relative;

      label {
        margin-bottom: 0;
        margin-left: 22px;
        font-size: 10px;
        top: 12px;
        position: relative;
        background: #fff;
        padding: 3px;
        padding-bottom: 0;
        padding-top: 0px;
      }

      input {
        &.form-control {
          border-radius: 0 !important;

          border-radius: 3px !important;
        }
      }

      i {
        &.searchicon {
          position: absolute;
          top: 11px;
          right: 14px;
          color: #808080;
        }

      }
    }

    .checkoptions {
      display: flex;

      .fillbutton {
        box-sizing: border-box;
        margin: 0px 10px 0px 0px;
        min-width: 0px;
        appearance: none;
        text-align: center;
        text-decoration: none;
        border-right: 0px;
        border-left: 0px;
        border-image: initial;
        border-radius: 4px;
        line-height: 1.5;
        cursor: pointer;
        padding: 3px 9px;
        height: 30px;
        background-color: rgb(255, 255, 255);
        color: rgb(164, 163, 163);
        font-family: SFProTextRegular;
        font-size: 12px;
        outline: none;
        border-top: none;
        box-shadow: rgb(229 230 235) 0px 0px 0px 1px inset;
        border-bottom: none;
        display: flex;
        -webkit-box-align: center;
        align-items: center;

        &.active {
          border-bottom: 2px solid #ca434f;

          .bPRapZ {
            border: 3px solid #ca434f;
          }

          span {
            color: #ca434f;
          }
        }

        .bPRapZ {
          box-sizing: border-box;
          margin: 0px;
          min-width: 0px;
          height: 16px;
          width: 16px;
          flex-shrink: 0;
          border: 1px solid #ddd;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .buttongroup {
      .btn {
        border-radius: 3px;
        margin: 5px;
      }
    }

    .checkoptions {
      .datedrop {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
        margin: 0px 5px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .group_label {
        width: 100%;
        margin: 0 3px;

        .threebox {
          width: 100%;
        }

        label {
          margin-bottom: 0;
          margin-left: 22px;
          font-size: 11px;
          top: 12px;
          position: relative;
          background: #fff;
          padding: 3px;
          padding-bottom: 0;
          padding-top: 0px;
          z-index: 99;
        }
      }
    }
  }

  .listing_area {
    .tableheader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #333;
      padding: 7px;
      margin: 25px 0 10px;
      border-radius: 0px;

      .hrow {
        span {
          color: #fff;
        }
      }
    }

    .tableconetent {
      display: flex;
      justify-content: space-between;
      padding: 5px 7px;
      background: #fff;
      border-radius: 0px;
      align-items: center;
      margin-bottom: 5px;
      border: 1px solid #ddd;
      position: relative;

      @media (max-width:767px) {
        display: block;
      }



      .stage {
        .progresssection {
          display: flex;
          line-height: 30px;

          .circlebox {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            max-width: 100%;
            width: 18px;
            height: 18px;
            display: block;
            border: 4px solid #0089c6;
            border-radius: 50%;
            margin-right: 5px;
          }

          .circletext {
            color: #000 !important;
          }

          .text {
            color: #0089c6;
          }
        }
      }

      .action {
        i {
          padding: 5px 8px;
          color: #898c8e;
          font-size: 19px;
          cursor: pointer;

          &.fa-trash-o {
            &:hover {
              color: rgb(233, 47, 47);
            }
          }

          &.fa-long-arrow-down {
            &:hover {
              color: #2f9ae2;
            }
          }
        }
      }
    }

    .paginationbox {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      span {
        padding: 8px;

        a {
          color: #909191;
        }

        &.number {
          a {
            color: #3b3b3b;
            font-size: 17px;
            padding: 5px;

            &.active {
              color: #1e97ff;
            }

            &:hover {
              text-decoration: underline;
            }
          }


        }
      }
    }
  }
}

.btn_group_btn {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 5px;
  }
}

.selctbox {
  width: 200px;
  height: 34px;
  border: 1px solid #ddd;
  padding: 7px 8px;
}

.datedrop {
  border: 1px solid #ddd;
  margin-left: 20px;
  margin-right: 20px;
  width: 220px;
  height: 37px;
  padding-left: 8px;

  .mat-date-range-input-separator {
    color: #000 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field {
    display: inline-block;
    position: relative;
    text-align: left;
  }

  mat-datepicker-toggle {
    position: absolute;
    top: 5px;
    right: -3px;
  }
}

mat-icon {
  position: relative;
  float: right;
  top: -38px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  right: 30px;
}

.suspenduser {
  background-color: #ec2e2b;
  color: #fff !important;
}

.suspenduser:hover {
  background-color: #ec2e2b !important;
  color: #fff !important;
  cursor: pointer;
}

// .manageruser { background-color: green; color: #fff !important;}
// .manageruser:hover { background-color: green !important; color: #fff !important; cursor: pointer;}
.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: auto !important;
}

.mat-date-range-input {
  // margin-top: 12px;
  height: 35px;
}

.mat-date-range-input-container {
  height: 25px;
  margin-top: 10px;
}

a {
  text-decoration: none;
}

.sample_doc {
  display: flex;
  justify-content: space-around;

  .doc_file {
    border: 3px solid #bd2130;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    margin-right: 10px;

    img {
      width: 100%;
    }

    .overdoc_pdf {
      background: #bd2130;
      text-align: center;
      color: #fff;
      padding: 10px;
      font-size: 18px;
      position: absolute;
      width: 100%;
      bottom: 0px;
      transition: .5s all;

    }

    &:hover {
      .overdoc_pdf {
        bottom: -70px;
      }
    }
  }
}

.headingdoc {
  padding-left: 37px;
  margin-bottom: 13px !important;
  font-size: 20px !important;
}

.bluecheck {
  color: #2d29df;

  font-weight: bold;
}

.iti__country {
  padding: 10px 15px !important;
}

.iti input#country-search-box {
  padding-left: 20px !important;
  height: 40px !important;
}

.iti__flag-box,
.iti__country-name {
  margin-right: 13px !important;
  font-size: 14px;
}

.iti.separate-dial-code .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag {

  border-right: 1px solid #ddd !important;
}

.flexsection {
  display: flex;
  justify-content: space-between;

  .forgotpasswordtext {
    color: #767373;
    text-decoration: none;
  }
}

.modal-content {
  border: none;
  padding: 10px 20px;
}

.headerinner {
  text-align: left;

  h2 {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0;
    color: #605e5e;
  }
}

hr {
  margin: 10px 0 !important;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.btn-danger {
  background: #e91e2f;
  border-color: #e91e2f;
}

.footer_box {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;

  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    margin: 5px;
  }

}

.caseheader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }

  .btngt {
    ul {
      padding-left: 0;
      display: flex;
      margin-bottom: 0;

      li {
        list-style: none;
        margin: 5px;

        &.redbg {
          .btn {
            background: #E91E63;
            border-color: #E91E63;
          }
        }

        &.orangebg {
          .btn {
            background: #FF9800;
            border-color: #FF9800;
          }
        }

        &.greenbg {
          .btn {
            background: #009688;
            border-color: #009688;
          }
        }

        span {
          margin: 0px 5px;
        }
      }
    }
  }
}

.form-control {
  -webkit-appearance: auto;
  appearance: auto;
}

.resetall {
  .jw-modal {
    width: 20%;
    display: flex;
    align-items: center;
    height: 100%;

    .jw-modal-body {
      .formgroup {
        .modal-content {
          .modal-header {
            h1 {
              font-size: 20px !important;
            }
          }

          .modal-body {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.changepassword {
  .jw-modal {
    width: 30%;
    display: flex;
    align-items: center;
    height: 100%;

    .jw-modal-body {
      .formgroup {
        .modal-content {
          .modal-header {
            margin-bottom: 15px;

            h1 {
              font-size: 20px !important;
            }
          }

          .modal-body {
            p {
              margin-bottom: 0;
            }
          }

          .modal-footer {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

.btn-secondary,
.btn-warning {
  font-size: 13px;
  border-radius: 3px;
  padding: 3px 20px;
}

.border_blue {
  color: blue !important;
  border: 1px solid blue !important;
  border-radius: 3px;
}

.border_blue:hover {
  background: blue !important;
  color: #fff !important;
}

.border_red {
  color: red !important;
  border: 1px solid red !important;
  border-radius: 3px;
}

.btn-dark {
  border-radius: 3px;
}

.cgap {
  font-size: 13px;
  border-radius: 3px;
  padding: 3px 20px;
  border-radius: 3px;
}

.border_gray {
  color: #646363 !important;
  border: 1px solid #646363 !important;
  border-radius: 3px;
}

.border_gray:hover {
  background: #646363 !important;
  color: #fff !important;
}

.awate {
  border-radius: 3px;
  border: 1px solid #999898;
  padding-left: 10px;
}

.border_red:hover {
  background: red !important;
  color: #fff !important;
}

.bdeflwx {
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.justifycontent {
  justify-content: space-between !important;
}

.empgap {
  border-radius: 5px;
  font-size: 13px;
  padding: 3px 10px;
  margin-right: 10px;
}

.status_dropdown {
  border: 1px solid #c7c7c7;
  background: transparent;
  padding: 0px 10px;
  border-radius: 5px;
}

.scircle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid green;
  font-size: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 600;
  color: green !important;
}

.container_bar {
  width: 500px;
  text-align: center;

  @media(max-width:767px) {
    width: 100%;
  }
}

.progress_wi {
  padding: 6px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 18px;
  background-color: #ee303c;
  border-radius: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-striped .progress-bar {
  background-color: #fcbc51;
  width: 100%;
  background-image: linear-gradient(45deg,
      rgb(252, 163, 17) 25%,
      transparent 25%,
      transparent 50%,
      rgb(252, 163, 17) 50%,
      rgb(252, 163, 17) 75%,
      transparent 75%,
      transparent);
  animation: progressAnimationStrike 40s;
}

.margin-top24 {
  margin-top: 24px !important;
}

.width100 {
  width: 100% !important;
}

@keyframes progressAnimationStrike {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.progress2 {
  padding: 6px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 18px;
  border-radius: 30px;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: 85%;
  background-color: #ef476f;
  animation: progressAnimation 6s;
}

.toast-top-center {
  width: 100% !important;
  margin: 0 auto;
}

@keyframes progressAnimation {
  0% {
    width: 5%;
    background-color: #f9bcca;
  }

  100% {
    width: 85%;
    background-color: #ef476f;
  }
}

$green: #4cd964;
$turquoise: #5ac8fa;
$blue: #007aff;
$light-blue: #7dc8e8;
$purple: #5856d6;
$red: #ff2d55;
$background: #2c303a;

.progress-bar3 {
  height: 18px;
  border-radius: 4px;
  background-image: linear-gradient(to right,
      $green,
      $turquoise,
      $blue,
      $light-blue,
      $purple,
      $red);
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-infinite .progress-bar3 {
  width: 100%;
  background-image: linear-gradient(to right,
      $green,
      $turquoise,
      $blue,
      $light-blue,
      $purple,
      $red);
  animation: colorAnimation 1s infinite;
}

@keyframes colorAnimation {
  0% {
    background-image: linear-gradient(to right,
        $green,
        $turquoise,
        $blue,
        $light-blue,
        $purple,
        $red);
  }

  20% {
    background-image: linear-gradient(to right,
        $turquoise,
        $blue,
        $light-blue,
        $purple,
        $red,
        $green);
  }

  40% {
    background-image: linear-gradient(to right,
        $blue,
        $light-blue,
        $purple,
        $red,
        $green,
        $turquoise);
  }

  60% {
    background-image: linear-gradient(to right,
        $light-blue,
        $purple,
        $red,
        $green,
        $turquoise,
        $blue);
  }

  100% {
    background-image: linear-gradient(to right,
        $purple,
        $red,
        $green,
        $turquoise,
        $blue,
        $light-blue);
  }
}

.footer_btn {
  text-align: right;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-dark_hr {
  font-size: 13px;
  border-radius: 3px;
  padding: 3px 20px;
  border-radius: 3px;
}

.ref_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .ref_card {
    width: 100%;
    background: #fff;
    box-shadow: 0px 1px 7px #d3d1d1;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;

    .userpic {
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;

      img {
        width: 33%;
        margin: 0 auto;
      }
    }

    .ref_name {
      text-align: center;
      font-size: 18px;
      display: block;
      color: #000;
    }

    .ref_designation {
      text-align: center;
      font-size: 15px;
      display: block;
    }

    .ref_date {
      text-align: center;
      font-size: 13px;
      display: block;
    }

    .ref_btn {
      text-align: center;
      margin: 10px;

      .btn-dark {
        padding: 3px 20px;
        font-size: 13px;
      }
    }
  }
}

.vdeta {
  padding: 3px 20px;
  font-size: 13px;
}

.referee_details_btn {
  .btn {
    margin: 0px 5px;
  }

  .upload_ref {

    order: 1;
    display: flex;
    align-items: center;
    height: 27px;
    width: 130px;

    &::before {

      text-align: center;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media(max-width:767px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .upload_ref {
      width: 49%;
      order: 1;
      display: flex;
      align-items: center;
      height: 27px;

      &::before {
        width: 100%;
        text-align: center;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .btn {
      width: 49%;
      margin: 0;
      margin-bottom: 5px;
    }
  }

}

.justify-felx-end {
  justify-content: flex-end !important;
}

.noteall_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .candidate_details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px 20px;
    padding-bottom: 12px;
    width: 49%;
    margin: 5px;

    @media(max-width:767px) {
      width: 100%;
    }

    .details {
      margin-top: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 25%;

      @media(max-width:1566px) {
        width: 33%;
      }

      @media(max-width:767px) {
        width: 100%;
      }

      span {
        font-size: 14px;
        color: #a4a3a3;
        line-height: 1.42;
        padding-bottom: 4px;
      }

      p {
        color: #525252;
        font-size: 14px;
        margin-bottom: 5px;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }
    }
  }
}

.centervertical_align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .header {
    .modal-title {
      font-size: 18px !important;

      span {
        i {
          font-size: 14px;
        }
      }
    }
  }

  .center_box_password {
    width: 35%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 8px #ddd;
    display: flex;
    align-items: center;
    justify-content: center;

    .header {
      padding: 5px 10px;
      border-bottom: 1px solid #ddd;
    }

    .content_section {
      padding: 15px;
      display: flex;

      .left_side {
        padding-left: 20px;
        padding-right: 50px;

        .details {
          margin-top: 20px;

          h4 {
            margin-bottom: 5px;
            color: #333;
            font-size: 22px;
          }

          .email {
            color: #727272;
            font-size: 13px;
          }
        }
      }
    }

    .mfooter {
      border-top: 1px solid #ddd;
      padding: 10px;
      text-align: right;
    }
  }
}

.forgetpagebox {
  width: 40%;
  margin: 0 auto;
}

.height_scroll {
  height: calc(100vh - 160px);
  overflow: scroll;
}

@media(max-width:1600px) {
  .tasklist .task .task_box {
    width: 23.35%;
  }
}

@media(max-width:1180px) {
  .width90 {
    max-width: 100%;
  }

  .footer {
    position: absolute;
  }

  .candidate_page .rightalks {
    flex-wrap: wrap;

    a {
      .box {
        margin: 10px;
        width: 100px;
        height: 100px;
      }
    }
  }

  .candidate_page .footertabde h4 {
    font-size: 15px;
  }

  .candidate_page .footertabde {
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 auto;
  }

  .candidate_page .right_box .box .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .border-box-group .candidate_details .details {
    width: 50%;
  }

  .candidate_page .left_box .box.tabnumbecountal h4 {
    font-size: 61px;
  }

  .candidate_page .left_box .box.tabnumbecountal h4 .totalcount {
    font-size: 100px;
  }

  .tasklist .task .task_box {
    width: 31%;
  }

  .candidate_details_show {
    .left_menu_text {
      width: 30%;
    }

    .right_content_section {
      width: 70%;

      .tabgroup-group {
        .right_content_section {
          width: 100%;
        }
      }
    }
  }

  .button-group .btn-danger span {
    margin-right: 10px;
    font-size: 14px;
  }




  .mat-datepicker-input {
    display: block;
    width: 100%;
    padding: 3px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: transparent;
    padding: 10px;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: solid 1px #c9c9c9;
    transition: border 0.3s;
  }

  .button-group .btn-danger {
    height: 58px;
    padding: 5px 0px;
  }

  .candidate_details_show .right_content_section .form_group .form-group {
    width: 100%;
    margin: 5px 0px;
  }


  .candidate_details_show .right_content_section .form_group .form-group label {
    font-size: 12px;
  }

  .candidate_details_show .right_content_section .form_group .form-group .text-muted {
    line-height: 16px;
  }
}

@media (max-width:1024px) {

  .mobile_group_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tasklist .task .task_box {
    width: 30%;
  }

  .candidate_details_show {
    .left_menu_text {
      width: 100%;
      padding: 20px 20px;
      position: fixed;
      top: 65px;
      left: -100%;
      transition: 0.5s all;
      z-index: 9999;
      height: calc(100vh - 46px);
      padding-right: 0;

      &.isMenuOpen {
        left: 0;
        width: 40%;
        transition: 0.5s all;
      }
    }

    .right_content_section {
      width: 100%;
    }
  }

  .circleicons {
    display: flex;
    bottom: 5%;
  }

  .width8,
  .width10,
  .width12 {
    width: 100%;
  }

  .width16 {
    width: 100%;
  }

  .width20 {
    width: 100%;
  }

  .width25 {
    width: 100%;
  }

  .width30 {
    width: 100%;
  }

  .width33 {
    width: 100%;
  }

  .width15,
  .width16 {
    width: 100%;
  }

  .width35 {
    width: 100%;
  }

  .width40 {
    width: 100%;
  }

  .width45 {
    width: 100%;
  }

  .width50 {
    width: 100%;
  }

  .width55 {
    width: 100%;
  }

  .width60 {
    width: 100%;
  }

  .width65 {
    width: 100%;
  }

  .width70 {
    width: 100%;
  }

  .width100 {
    width: 100%;
  }

  .width32 {
    width: 100%;
  }

  .request_page .listing_area .tableconetent {
    display: block;
    width: 33%;
  }

  .request_page .listing_area .tableheader {
    display: none;
  }

  .request_page .listing_area .tableheader {
    display: none;
  }

  .request_page .listing_area .tableconetent .hrow .mobileShow {
    display: block;
  }

  .request_page .listing_area .tableconetent .hrow .label {
    display: block;
    color: #6c6262;
    font-size: 12px;
  }

  .request_page .listing_area .tableconetent .hrow span {
    color: #202020;
    font-size: 11px;
  }

  .request_page .listing_area .tableconetent .hrow {
    margin-bottom: 10px;
  }

  .request_page .listing_area .tableconetent .action i.fa-edit {
    background: #ee313a;
    color: #fff;
    border-radius: 3px;
    display: block;
    text-align: center;
  }

  .request_page .listing_area .tableconetent .action i.fa-trash-o {
    background: #525050;
    color: #fff;
    border-radius: 3px;
    display: block;
    text-align: center;
  }

  .request_page .listing_area .tableconetent .hrow.action {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .request_page .filter_option {
    display: flex;
  }

  //   .request_page .filter_option .searchbox {
  //     width: 50%;
  // }
  .datedrop {
    width: 175px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 11px;
  }

  .mat-date-range-input {
    margin-top: 15px;
  }

  .candidate_page .left_box.tableftbg {
    margin-bottom: 10px;
    height: 265px;
  }

}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (max-width:980px) {
  .tasklist .task .task_box {
    width: 46.5%;
  }

  .button-group .btn-danger span {
    margin-right: 10px;
    font-size: 14px;
  }

  .button-group .btn-danger {
    height: 58px;
    padding: 5px 0px;
  }

  .candidate_details_show .right_content_section .form_group .form-group label {
    font-size: 12px;
  }

  .request_page .listing_area .tableheader {
    display: none;
  }

  .request_page .listing_area .tableconetent {
    display: block;
  }

  .request_page .listing_area .tableconetent .hrow {
    margin-bottom: 10px;
    text-align: left !important;
  }

  .request_page .listing_area .tableconetent .hrow .label {
    display: block;
    color: #525151;
    font-size: 11px;
    word-break: break-all;
  }

  .request_page .listing_area .tableconetent .hrow span {
    font-size: 14px;
    word-break: break-all;
    color: #000;
  }

  .mobile_group_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tableconetent .hrow.actionbtngrup {
    flex-direction: row-reverse;
  }

  .candidate_page .footertabde .infoemph p:first-child {
    border: none;
  }

  .candidate_page .footertabde .infoemph {
    display: flex;
    flex-direction: column;
  }
}

@media(max-width:900px) {
  .candidate_page {
    .left_box {
      background: transparent;

      .box {
        &.tabnumbecountal {
          height: 265px;
          margin-bottom: 0;
        }
      }

      &.tableftbg {
        margin-bottom: 10px;
        height: 265px;
      }
    }

    .right_box {
      .box {
        .header {

          // flex-direction: column;
          h3 {
            &.usernamehight {
              width: auto;
            }
          }

        }

        .border-box-group {}
      }
    }

    .rightalks {
      flex-direction: column;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        .box {
          margin: 10px;
        }

        &:last-child {
          .box {
            margin: 10px;
          }
        }
      }
    }

    .footertabde {
      flex-direction: column;

      .infoemph {
        margin-top: 5px;

      }
    }

  }

  .noboardershow {
    padding-left: 0 !important;
  }

  .summary_page {
    .candidate_page {
      .right_box {
        .box {
          .border-box-group {
            .candidate_details {
              .details {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  .candidate_page .left_box .box.tabnumbecountal h4 {
    font-size: 40px;
    margin-bottom: 0;

    .totalcount {
      font-size: 90px;
    }
  }

  .candidate_details_show {
    .left_menu_text {
      width: 100%;
      padding: 20px 20px;
      position: fixed;
      top: 65px;
      left: -100%;
      transition: 0.5s all;
      z-index: 9999;
      height: calc(100vh - 46px);

      &.isMenuOpen {
        left: 0;
        width: 65%;
        transition: 0.5s all;
        padding-right: 0;
      }
    }

    .right_content_section {
      width: 100%;
    }
  }

  .circleicons {
    display: flex;
  }

  .footer {
    position: absolute;
  }
}

@media (min-width:768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (max-width:768px) {
  .tasklist .task .task_box {
    width: 45%;
  }

  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (max-width:767px) {
  .candidate_page {
    flex-direction: column;

    .right_box {
      margin-left: 0;
      // height: calc(100vh - 334px);
      overflow: auto;
    }

    .accordion_box {
      .acc_content {
        .candidate_details {
          flex-direction: column;
        }
      }
    }
  }

  .category .catog_box {
    width: 100%;
  }

  .candetails .jw-modal {
    display: block;
  }

  .width30,
  .width70 {
    width: 100%;
  }

  .candidate_details_show .right_content_section {
    width: 100%;
    padding: 5px;
  }

  .candidate_details_show .left_menu_text.isMenuOpen {
    width: 100%;
  }

  .candidate_details_show .left_menu_text .candidate_de {
    padding: 30px 7px;
  }

  .footertabde {
    .infoemph {
      display: inline !important;

      p:first-child {
        border-right: 0px !important;
        border-bottom: 1px solid #ddd;
      }

      p {
        font-size: 11px;
      }
    }
  }

  .summary_page {
    padding: 15px;
    margin-bottom: 0;
  }

  .sidebar .nav {
    overflow: scroll;
  }

  .request_page {
    .filter_option {
      display: block;

      .searchbox {
        margin-bottom: 5px;
      }

      .checkoptions {
        display: block;

        .threebox {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }

  .btn_group_btn {
    display: flex;
    justify-content: flex-start;
  }

  .camerashap_aligncenter {
    flex-direction: column;

    .actionBtn {
      margin-bottom: 5px;
    }
  }
}

@media (max-width:580px) {
  .request_page .listing_area .tableconetent {
    width: 100%;
  }

  .request_page .filter_option .checkoptions .fillbutton {
    width: 100%;
    margin: 10px 0;
  }
}