@import "skins/blue";

$sidebarcolor: #777777;
$body-bg-color: #e9ebee;
$text-color: #242424;
$gray-dark: #292b2c;
$gray: #555;
$gray-light: #ccc;
$gray-lighter: #eceeef;

$table-bg-active: rgba(#000,.075);
$table-border-width: 1px;
$table-border-color: $gray-lighter;

/**** App roots ****/
$assets-root: '~/assets/';
$images-root: $assets-root + 'img/';
$fonts-root: $assets-root + 'fonts/';


/**** General ****/
$font-family: 'Roboto', sans-serif;


/**** Navbar ****/
$navbar-text-color: $default-color;
$navbar-height: 60px;


/**** Sidebar ****/
$sidebar-width: 230px;
$sidebar-width_op:100%;

/**** Progress ****/
$progress-border-radius: 0;
$progress-height: 20px;


/**** Fonts ****/
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 500;
$font-bolder: 700;
$font-ultraBold: 900;


/**** Social icons ****/
$facebook-color: #3b5998;
$twitter-color: #55acee;
$instagram-color: #8a3ab9;
$pinterest-color: #c92228;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;

//--------for prevent awesome-bootstrap-checkbox errors---------
//@import "../../../../node_modules/bootstrap/scss/variables";
//@import "../../../../node_modules/bootstrap/scss/mixins";
$input-border-color: rgba(#000,.15);
$enable-transitions: true;
$input-color: $gray;
$input-bg-disabled: $gray-lighter;
$brand-primary: $primary-color;
$brand-success: $success-color;
$brand-info: $info-color;
$brand-warning: $warning-color;
$brand-danger: $danger-color;
$brand-inverse: $gray-dark;




//Set variables to be accessible for JS
@import "../../../../node_modules/sass-to-js/sass/sass-to-js";

$variablesMap: (
  main-color: $main-color,
  default-color: $default-color,
  dark-color: $dark-color,
  primary-color: $primary-color,
  success-color: $success-color,
  warning-color: $warning-color,
  danger-color: $danger-color,
  info-color: $info-color,
  sidebar-bg-color: $sidebar-bg-color,
  gray: $gray,
  gray-light: $gray-light
);

body::after {
    content: sassToJs($variablesMap);
    display: none;
}









.new_request {
  background: #fff;
  padding: 15px;
  box-shadow: 1px 2px 3px #ddd;
  margin-bottom: 20px;
  margin: 0px 0 0;
  border-radius: 3px;
  h4 {
    margin-bottom: 5px;
        font-size: 18px;
        color: #525252;
  }
  .importcsv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      margin-bottom: 0;
    }
    .csv{
      border-radius: 3px;
      background: #0089c6;
      border-color:#0089c6;
      i{
        margin-left: 10px;
      }
    }
  }
  .entryform{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .formtextbox  {
      margin: 10px;
      label{
        box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 14px;
    color: #525252;
    line-height: 1.42;
    padding-bottom: 10px;
     display: inline-block;
      }
      .form-control {
        height: 38px;
        border-radius: 3px;
      }
    }
  }
  .custom-control {
    margin: 10px;
  }
}
.button_option {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 50px;
  .btn-secondary {
    background: #a4a3a3;
    border-radius: 3px;
    border-color:#a4a3a3;
  }
  .buttonwithcredit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      box-sizing: border-box;
      margin: 0px;
      min-width: 0px;
      font-size: 14px;
      color: #525252;
      line-height: 1.42;
    }
    .sendrequest {
      background: #e73f34;
      border-radius: 3px;
      margin-left: 15px;
      border-color:#e73f34;
    }
  }
}


.new_request {
  background: #fff;
  padding: 15px;
  box-shadow: 1px 2px 3px #ddd;
  margin-bottom: 20px;
  margin: 0px 0 0;
  border-radius: 3px;
  h4 {
    margin-bottom: 5px;
        font-size: 18px;
        color: #525252;
  }
  .importcsv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      margin-bottom: 0;
    }
    .csv{
      border-radius: 3px;
      background: #0089c6;
      border-color:#0089c6;
      i{
        margin-left: 10px;
      }
    }
  }
  .entryform{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .formtextbox  {
      margin: 10px;
      label{
        box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 14px;
    color: #525252;
    line-height: 1.42;
    padding-bottom: 10px;
     display: inline-block;
      }
      .form-control {
        height: 38px;
        border-radius: 3px;
      }
    }
  }
  .custom-control {
    margin: 10px;
  }
}




.new_request {
  background: #fff;
  padding: 15px;
  box-shadow: 1px 2px 3px #ddd;
  margin-bottom: 20px;
  margin: 0px 0 0;
  border-radius: 3px;
  h4 {
    margin-bottom: 5px;
        font-size: 18px;
        color: #525252;
  }
  .importcsv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      margin-bottom: 0;
    }
    .csv{
      border-radius: 3px;
      background: #0089c6;
      border-color:#0089c6;
      i{
        margin-left: 10px;
      }
    }
  }
  .entryform{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding: 0;
    .formtextbox  {
      margin: 10px;
      label{
        box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 14px;
    color: #525252;
    line-height: 1.42;
    padding-bottom: 10px;
     display: inline-block;
      }
      .form-control {
        height: 38px;
        border-radius: 3px;
      }
    }
  }
  .custom-control {
    margin: 10px;
  }
}
.button_option {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 50px;
  .btn-secondary {
    background: #a4a3a3;
    border-radius: 3px;
    border-color:#a4a3a3;
  }
  .buttonwithcredit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      box-sizing: border-box;
      margin: 0px;
      min-width: 0px;
      font-size: 14px;
      color: #525252;
      line-height: 1.42;
    }
    .sendrequest {
      background: #e73f34;
      border-radius: 3px;
      margin-left: 15px;
      border-color:#e73f34;
    }
  }
}

.disableicon{
  opacity: 0.6
}
.newrequest_cre {
  background: #fff;
  padding: 15px;
  box-shadow: 1px 2px 3px #ddd;
  margin-bottom: 20px;
  border-radius: 3px;
  .header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h4 {
      margin-bottom: 5px;
          font-size: 18px;
          color: #525252;
    }
    ul{
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      width: 40%;
      justify-content: space-around;
    align-items: center;
      li{
        list-style: none;
        width: 50%;
        a {
          display: block;
          color: #6c6c6c;
          padding: 10px 0px;
          margin-right: 15px;
          &:hover{
            border-bottom: 3px solid #e73f34;
            padding-bottom: 8px;
          }
        }
        &.active{
          a{
            border-bottom: 3px solid #e73f34;
          padding-bottom: 8px;
          }
        }
      }
    }
  }
  .content_area {
    padding: 35px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    align-items: flex-start;
    h4{
      font-size: 18px;
      margin-bottom: 20px;
    }
    .left_area {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
    }
    .right_area {
      width: 50%;
      .scroll_box {
        padding: 15px;
        border: 1px solid #f0f0f0;
        border-radius: 3px;
        max-height: 200px;
        overflow: auto;
        ul{
          padding-left: 0;
          li{
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            i{
              cursor: pointer;
              color: #7c7878;
            }
            p{
              margin-bottom: 0;
            }
            span{
              color: #7c7878;
              line-height: 15px
            }
          }
        }
      }
    }
}
}
.flex_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
  .next{
    border-radius: 3px;
  }
}
.radio_con {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size:14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio_con input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  outline: 1px solid #cbc5c5 !important;
}

/* On mouse-over, add a grey background color */
.radio_con:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio_con input:checked ~ .checkmark {
  background-color: rgb(244 67 54 / 33%);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio_con input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio_con .checkmark:after {
 	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #f44336;
}





@media (max-width:1200px){
  .newrequest_cre .content_area .left_area {
    width: 100%;
  }
  .newrequest_cre .content_area .right_area {
    width: 100%;
  }
}
@media (max-width:767px){
  .newrequest_cre .content_area {
    padding: 0px;
  }
  .newrequest_cre .content_area .left_area {
    width: 100%;
  }
  .newrequest_cre .content_area .right_area {
    width: 100%;
  }
  .radio_con {
    font-size: 12px;
    padding-left: 27px;
  }
  .newrequest_cre .content_area .left_area .radio_con {
    margin-bottom: 11px;
  }
  
  .newrequest_cre .content_area .right_area .scroll_box ul li {
    margin-bottom: 8px;
  }
  .newrequest_cre .content_area .right_area .scroll_box ul li p {
    font-size: 13px;
  }
  .newrequest_cre .content_area .right_area .scroll_box ul li span {
    font-size: 12px;
  }
  .width48 {
    width: 100%;
  }
  .width8{
    width: 100%;
  }
  .width16{
    width: 100%;
  }
  .width20{
    width: 100%;
  }
  .width25{
    width: 100%;
  }
  .width30{
    width: 100%;
  }
  .width35{
    width: 100%;
  }
  .width40{
    width: 100%;
  }
  .width45{
    width: 100%;
  }
  .width50{
    width: 100%;
  }
  .width55{
    width: 100%;
  }
  .width60{
    width: 100%;
  }
  .width65{
    width: 100%;
  }
  .width70{
    width: 100%;
  }
  .width100{
    width: 100%;
  }
  .request_page  {
    .filter_option {
      display: block;
      .searchbox {
        margin-bottom: 5px;
      }
      .checkoptions  {
        display: block;
        .threebox {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
    .listing_area {
      .tableheader{
        display: none ;
      }
    }
  }
}